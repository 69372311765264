const GAMMA_ENDPOINT: string = "https://dev.ma.globalsellingcommunity.cn/app/amazon/courseThird/CheckSignUpToken";
const PROD_ENDPOINT: string = "https://ma.globalsellingcommunity.cn/app/amazon/courseThird/CheckSignUpToken";

// prod will get replaced by Katal.
// See: https://katal.amazon.dev/for-developers/build-and-deploy/manifest-templating/
// See: https://sage.amazon.com/posts/1216615 why it's using String constructor
const stage: string = String("prod");
const endpoint: string = stage === "prod" ? PROD_ENDPOINT : GAMMA_ENDPOINT;

export async function generateContractFileWithoutSign(data: string): Promise<Response> {
    const formData = new FormData();
    formData.append("data", data);
    formData.append("type", "1");
    formData.append("processData", "false");
    formData.append("mimeType", "multipart/form-data");
    formData.append("contentType", "false");

    return await fetch(endpoint, {
        method: "POST",
        body: formData
    });
}

export async function createContract(data: string, signature: string): Promise<Response> {
    const formData = new FormData();
    formData.append("data", data);
    formData.append("type", "2");
    formData.append("signature", signature);
    formData.append("processData", "false");
    formData.append("mimeType", "multipart/form-data");
    formData.append("contentType", "false");

    return await fetch(endpoint, {
        method: "POST",
        body: formData
    });
}
